import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Components
import Blogik, { BlogConsumer } from 'components/shared/Blogik'
import { BlogGrid, BlogBannerFilter } from 'components/flex/Blog'

const StyledBlogGrid = styled(BlogGrid)`
  top: 0 !important;
`

const VacanciesBlogBannerFilter = styled(BlogBannerFilter)`
  background-color: ${({ theme }) => theme.color.face.border};
`

interface VacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Vacancies
}

const Vacancies: React.FC<VacanciesProps> = ({ fields }) => (
  <section className="mt-4 mt-lg-5" id="vacatures">
    <div className="container py-4 py-lg-5">
      <ParseContent content={fields?.description} />

      <div className="mt-2">
        <Blogik
          type="vacancies"
          settings={{
            id: 'vacancies',
            limit: 50,
          }}
          filterName="vacancyCategories"
        >
          <Filters />
          <StyledBlogGrid
            fields={{ ...fields, ...{ viewtype: 'vacancies' } } as any}
          />
          <Grid />
        </Blogik>
      </div>
    </div>
  </section>
)

const Filters = () => {
  const {
    categories,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesCategoryQueryQuery>(graphql`
    query vacanciesCategoryQuery {
      categories: allWpVacancyCategory(filter: { count: { gt: 0 } }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <BlogConsumer>
      {(context: any) => (
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="mt-3 row justify-content-center">
              {categories.edges.map((category) => (
                <div
                  className="col-6 col-md-4 col-xl-3 mb-4"
                  key={category.node.id}
                >
                  <VacanciesBlogBannerFilter
                    selected={context.isSelected(category.node.slug)}
                    slug={category.node.slug}
                    id={category.node.id}
                    className="mr-3 d-flex align-items-center h-100"
                  >
                    {category.node.name}
                    <svg
                      className="ml-3"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 496.124 496.124"
                    >
                      <g>
                        <path
                          d="M420.001,219.7c3.666,14.313,5.811,29.238,5.811,44.676c0,99.828-81.199,181.024-181.022,181.024
  c-99.821,0-181.039-81.195-181.039-181.024c0-99.824,81.218-181.041,181.039-181.041c25.442,0,49.633,5.338,71.623,14.848
  l26.912-43.26c-29.949-14.15-63.27-22.307-98.535-22.307c-127.793,0-231.764,103.971-231.764,231.76
  c0,127.779,103.971,231.748,231.764,231.748c127.774,0,231.743-103.969,231.743-231.748c0-35.516-8.271-69.062-22.599-99.178
  L420.001,219.7z"
                        />
                        <path
                          d="M463.159,6.385c-19.811-12.32-45.882-6.279-58.217,13.533L236.88,289.985l-56.299-80.289
  c-13.389-19.104-39.775-23.729-58.879-10.338c-19.1,13.408-23.742,39.756-10.336,58.877l93.002,132.633
  c7.93,11.291,20.855,17.996,34.609,17.996c0.379,0,0.777,0,1.157-0.018c14.181-0.396,27.229-7.871,34.72-19.914L476.702,64.6
  C489.05,44.766,482.972,18.713,463.159,6.385z"
                        />
                      </g>
                    </svg>
                  </VacanciesBlogBannerFilter>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </BlogConsumer>
  )
}

const GridContent = styled(ParseContent)`
  color: ${({ theme }) => theme.color.text.purple};

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
    text-align: center;
  }
`

const Grid = () => (
  <BlogConsumer>
    {(context: any) =>
      context.posts.length === 0 && (
        <div className="text-center py-5">
          <GridContent content="<h2>Geen vacatures gevonden met de gekozen filters</h2><p>Probeer het met de andere filters</h2>" />
        </div>
      )
    }
  </BlogConsumer>
)

export default Vacancies
